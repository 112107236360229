<template>
    <div v-if="loading">Loading ...</div>
    <div v-else class="w-full h-screen">
        <div id="application-side-menu">
            <div v-if="sideOpen" class="fixed inset-0 overflow-hidden bg-gray-400 bg-opacity-50" style="z-index: 999;">
                <div class="absolute inset-0 overflow-hidden">
                    <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <div class="w-screen max-w-md">
                            <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <header class="space-y-1 py-6 px-4 bg-blue-700 sm:px-6">
                                    <div class="flex items-center justify-between space-x-3">
                                        <h2 class="text-lg leading-7 font-medium text-blue-300">
                                            <translate>Welcome</translate>
                                        </h2>
                                        <div class="h-7 flex items-center">
                                            <button aria-label="Close panel" class="text-blue-200 hover:text-white transition ease-in-out duration-150" @click="sideOpen = !sideOpen">
                                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-sm leading-5 text-white">
                                            {{ $store.getters['master/user'].first_name }} {{ $store.getters['master/user'].last_name }}
                                            <translate>for</translate>
                                            <b>{{ $store.getters['master/company'].name }}</b><br/>
                                            {{ $store.getters['master/unit'].name }} <b>{{ $store.getters['master/brand'].name }}</b><br/>
                                        </p>
                                        <div class="mt-2 text-xs text-blue-200">{{ $store.getters['master/user'].email }}</div>
                                        <div class="mt-2 text-sm flex flex-row justify-between">
                                            <a class="text-blue-300 hover:underline cursor-pointer" @click="logOut">
                                                <translate>Log out</translate>
                                            </a>
                                            <a v-if="$store.getters['master/user'].is_staff" class="text-blue-300 hover:underline cursor-pointer" href="/admin">
                                                <translate>Administration</translate>
                                            </a>
                                            <a v-if="$store.getters['master/user'].is_staff" class="text-blue-300 hover:underline cursor-pointer" @click="updateCompany">
                                                <translate>Update company</translate>
                                            </a>
                                            <a @click="switchCompany" class="text-blue-300 hover:underline cursor-pointer">
                                                <translate>Switch</translate>
                                            </a>
                                        </div>
                                    </div>
                                </header>
                                <div class="relative flex-1 py-6 px-4 sm:px-6">
                                    <div class="absolute inset-0 py-6 px-4 sm:px-6">
                                        <div class="h-full border-2 border-dashed border-gray-200"></div>
                                    </div>
                                    <!-- /End replace -->
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div v-if="$store.getters['master/detailOpen']">
            <router-view name="detail"></router-view>
        </div>
        <div class="app-container">
            <div class="app-header-menu sticky top-0">
                <div class="flex flex-col app-menu" id="application-menu">
                    <div :style="{height: '5px', backgroundColor: '#' + $store.getters['master/application'].color}" class="w-screen"></div>
                    <div class="flex justify-between w-screen flex-1 flex-row">
                        <div class="flex flex-1 overflow-x-scroll w-max flex-grow mr-4" style="height: 56px;">
                            <div class="flex">
                                <div class="flex items-center selected-menu-item" v-if="!headOpen" :style="{ width: selectedWidth }" @click="headOpen = !headOpen">
                                    <div class="">
                                        <div :class="{'menu-item-title': true, 'text-blue-900': !headOpen, 'text-white': headOpen}">Autralis.</div>
                                        <div :class="{'menu-item-app': true, 'text-blue-900': !headOpen, 'text-white': headOpen}">
                                            {{ $store.getters['master/application'].name }}
                                        </div>
                                    </div>
                                    <div style="font-size: 26px; padding-top: 7px; margin-left: 24px" v-bind:class="{'text-blue-800': !headOpen, 'text-white': headOpen }">
                                        <i class="fal fa-chevron-double-right"/>
                                    </div>
                                </div>
                                <div v-for="app in applications"
                                     :key="app.id"
                                     class="flex flex-col item z-60"
                                     :style="{width: width(), backgroundColor: '#' + app.color, paddingLeft: headOpenValue(headOpen, '20px', '0px')}"
                                     @click="selectApp(app)">
                                    <div class="menu-item-title" :style="{opacity: opacity()}">Autralis.</div>
                                    <div class="menu-item-app" :style="{opacity: opacity()}">{{ app.name }}</div>
                                </div>
                            </div>

                        </div>

                        <div class="flex h-full">
                            <!--                        <div class="global-search-wrapper" style="width: 300px; margin-right: 20px;">-->
                            <!--                            &lt;!&ndash; here was autocomplete searchbar &ndash;&gt;-->
                            <!--                        </div>-->
                            <!-- <AppUpdate/> -->
                            <!--                        <div class="flex flex-row items-center">-->
                            <!--                            &lt;!&ndash;  here was the language menu selector &ndash;&gt;-->
                            <!--                            <div class="mr-10">-->
                            <!--                                &lt;!&ndash; <LanguagesComp/> &ndash;&gt;-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <div class="flex justify-center">
                                <div class="flex text-gray-800 mr-6 cursor-pointer" @click="sideOpenToggle">
                                    <div class="flex items-center">
                                        <div class="inline-block relative">
                                            <v-gravatar :email="$store.getters['master/user'].email" class="h-10 w-10 rounded-full" alt=""/>
                                            <span class="absolute top-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid bg-green-400"></span>
                                        </div>
                                        <div class="ml-3 -mt-1">
                                            <p class="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                                                {{ $store.getters['master/user'].first_name }} {{ $store.getters['master/user'].last_name }}
                                            </p>
                                            <p class="text-xs leading-4 font-medium text-gray-300 group-hover:text-gray-500 group-focus:underline transition ease-in-out duration-150">
                                                <translate>Open menu</translate>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="flex flex-1 app-content-container overflow-y-scroll" :style="{'height': contentContainerPanelHeight + 'px'}">

                <div class="blocks-menu" id="app-blocks-menu">
                    <div v-for="subMenu in $store.getters['master/application'].subMenus"
                         :key="subMenu.id"
                         id="menu-block"
                         :class="['app-menu-block text-center py-6 pb-4 text-xs flex flex-col leading-6',  $store.getters['master/subMenu'].id == subMenu.id ? 'block-active' : '']"
                         @click="setSubMenu(subMenu)">
                        <span style="color: #2c3880; font-size: 26px" :class="[subMenu.icon]"></span>
                        <span class="text-xs font-light text-blue-900">{{ subMenu.name }}</span>
                    </div>
                </div>

                <div class="flex content-container-content w-full">
                    <slot></slot>
                </div>
            </div>

            <div id="footer" class="module footer font-medium">
                <div class="headersub text-xs">
                    <div class="float-left text-gray-700">
                        &copy; Copyright {{ new Date().getFullYear() }}
                        <a href="http://www.autralis.com" title="" class="text-blue-800 ml-2">Autralis </a>
                        <span class="text-3xs text-gray-700 ml-2">0.7.2</span>
                    </div>
                    <div class="float-right text-gray-800">
                        Helpdesk : <a href="mailto:support@usedcars.center" class="text-blue-800">support@usedcars.center</a> &nbsp; - &nbsp;
                        NL : <a href="callto:0032093200023" class="text-blue-800">+32 (0)9 320 00 23</a> &nbsp; - &nbsp;
                        FR : <a href="callto:0032093200033" class="text-blue-800">+32 (0)9 320 00 33</a> &nbsp; - &nbsp;
                        WhatsApp : <a href="https://api.whatsapp.com/send?phone=32472060140" target="_blank" rel="noopener noreferrer" class="text-blue-800">32 (0)472 06 01 40</a>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from "@/router";
import {head} from "lodash";

const MENU_WIDTH = 285;

export default {
    name: "LayoutMain",
    data: function () {
        return {
            loading: true,
            sideOpen: false,
            headOpen: false,
            selectedWidth: MENU_WIDTH,
            applications: this.$store.getters['master/applications'],
            contentContainerPanelHeight: window.innerHeight - 92
        }
    },
    created: function () {
        if (!this.$store.getters['master/isAuthenticatedFull']) {
            router.push('login')
            return;
        }
        if (this.$store.getters["master/applications"].length <= 0) {
            const url = `/manager/api/v1/application/config/${this.$store.getters['master/company'].id}/${this.$store.getters['master/unit'].id}/${this.$store.getters['master/brand'].id}`
            axios.get(url, {withCredentials: true}).then(response => {
                this.$store.commit('master/setApplications', response.data.applications);
                this.loading = false;
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        sideOpenValue: function (t, vt, vf) {
            return this.sideOpen ? vt : vf
        },
        sideOpenToggle: function () {
            this.sideOpen = !this.sideOpen
        },
        headOpenValue: function (t, vt, vf) {
            return this.headOpen ? vt : vf
        },
        selectApp: function (application) {
            this.headOpen = false;
            this.$store.commit("master/setApplication", application)
        },
        width: function () {
            return this.headOpen ? "240px" : "0px"
        },
        opacity: function () {
            return this.headOpen ? 1 : 0
        },
        switchCompany: function () {
            router.push('login-company')
        },
        logOut: function () {
            this.$store.commit("master/logOut")
            router.push('/login')
        },
        setSubMenu: function (block) {
            this.$store.commit("master/setSubMenu", block);
        },
        updateCompany: function () {
            //showAutralisOngoing("Updating");
            //$.get('/manager/integration/update-company/', function () {
            //closeAutralisOngoing();
            //jAlert("Running verify in 5 minutes", "Autralis", function() { return; });
            //}).error(function () {
            //closeAutralisOngoing();
            //jAlert("Updating failed", "Autralis", function() { return; });
            //});
        },

        calculateContentHeight() {
            const header = head(document.getElementsByClassName('app-header-menu'))
            const footer = head(document.getElementsByClassName('footer'));

            let height = 0;
            if (header) height = height + header.clientHeight;
            if (footer) height = height + footer.clientHeight;

            this.contentContainerPanelHeight = window.innerHeight - height;
        },

        resizeWindow: function () {
            this.calculateContentHeight();
        }
    },

    mounted() {
        this.calculateContentHeight();
    }
}

</script>

<style scoped>
.app-header-menu {
    display: flex;
    flex-direction: row;
    height: 61px;
    background-color: #f9fafb;
}

.app-content-container {
    display: flex;
    flex-direction: row;
    background-color: #f9fafb;
}

.app-menu-block {
    margin-top: 4px;
    display: flex;
    height: 62px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid #f9fafb;
}


.content-container-panel {
    width: 300px;
    color: white;
    background-color: #f9fafb;
    z-index: 10;
    border-top-left-radius: 8px;
}

@media (min-width: 1024px) {
    .content-container-panel {
        background-color: #fff;
    }
}

.block-active {
    background-color: #e5e9f2;
    border: 2px solid #e5e9f2;
}

.menu-block:hover {
    background-color: #e5e9f2;
    border: 2px solid #e5e9f2;
}

.content-container-content {
    display: flex;
    flex: 1;
    background-color: #fff;
    overflow: scroll;
    height: calc(100vh - 85px);
}

.header-menu {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    width: auto;
    background-color: #f9fafb;
    align-items: center;
    align-content: center;
    margin-left: 284px;
    font-size: 14px;
    font-weight: 500;

}


.footer {
    border: 0;
    border-top: 1px solid #f9fafb;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    background: #f9fafb;
    color: white;
    position: fixed;
    z-index: 9;
    bottom: 0;
    margin: 0;
    padding: 7px 20px;
    min-width: 100px;
    width: 100%;
    opacity: 1;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


.global-search-wrapper {
    padding-right: 50px;
}


.global-search {
    width: 100%;
}


.global-search-item-count {
    position: absolute;
    right: 16px;
}

.blocks-menu {
    width: 85px;
    background-color: #f9fafb;
    padding: 0 6px;
    z-index: 8;

}

@media (min-width: 1024px) {
    .blocks-menu {
        box-shadow: 3px 0 3px -3px #ccc;
    }
}

.app-menu {
    z-index: 10;
    position: absolute;
    height: 61px;
}

.selected-menu-item {
    float: left;
    min-height: 49px;
    max-height: 49px;
    color: #364a63;
    padding-left: 20px;
    cursor: pointer;
    margin-top: 4px;
}

.item {
    float: left;
    color: white;
    transition: width 0.2s;
    min-height: 49px;
    max-height: 49px;
    cursor: pointer;

}

.menu-item-title {
    font-size: 14px;
    max-height: 8px;
    margin-top: -1px;
}

.menu-item-app {
    margin-top: 4px;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
}

</style>